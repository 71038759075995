import { render, staticRenderFns } from "./GoldenRingModal.vue?vue&type=template&id=7ca2d268&scoped=true&"
import script from "./GoldenRingModal.vue?vue&type=script&lang=js&"
export * from "./GoldenRingModal.vue?vue&type=script&lang=js&"
import style0 from "./GoldenRingModal.vue?vue&type=style&index=0&id=7ca2d268&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ca2d268",
  null
  
)

export default component.exports